<template>
  <el-popconfirm
    :confirm-button-text="confirmText ? confirmText : 'OK'"
    cancel-button-text="No"
    icon="el-icon-info"
    icon-color="red"
    :title="title"
    @confirm="confirm"
    :data="item"
  >
    <!-- <i  slot="reference "class="el-icon-edit"></i> -->

    <span slot="reference">
      <el-tooltip
        class="item"
        effect="dark"
        :content="toolTipTitle"
        placement="top"
      >
        <v-icon
          class="is-pointer"
          :color="confirmButtonColor ? confirmButtonColor : 'deep-orange'"
        >
          {{ confirmIcon }}</v-icon
        >
      </el-tooltip>
    </span>
  </el-popconfirm>
</template>

<script>
export default {
  name: "ConfirmAction",

  props: {
    confirmFunction: { type: Function, required: false },
    confirmIcon: { type: String, required: false },
    confirmButtonColor: { type: String, required: false },
    confirmText: { type: String, required: false },
    title: { type: String, required: false },
    item: { type: Object, required: false },
    deleteButtonColor: { type: String, required: false },
    toolTipTitle: { type: String, required: false },
  },
  methods: {
    confirm() {
      this.confirmFunction(this.item);
    },
  },
};
</script>
<style scoped>
.is-pointer {
  cursor: pointer;
}
</style>
